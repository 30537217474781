<template>
  <v-app class="custom-bg">
    <v-locale-provider :rtl="useIsRtl()">
      <v-layout style="display: block">
        <div v-if="store" :style="backgroundStyle">
          <header>
            <LayoutNavbarDomain class="w-full fixed top-0 z-40">
              <button
                :aria-label="t('open_drawer')"
                class="ltr:mr-2 rtl:ml-2"
                @click="isOpen = true"
              >
                <v-icon icon="mdi mdi-menu" :size="24" class="text-text_color" />
              </button>
            </LayoutNavbarDomain>
          </header>

          <v-navigation-drawer
            v-model="isOpen"
            location="right"
            temporary
            class="custom"
          >
            <LayoutNavbarDomainDrawer @close="isOpen = false" />
          </v-navigation-drawer>

          <main>
            <div class="h-12 sm:my-1"></div>
            <slot />
          </main>

          <footer>
            <LayoutWhatsapp
              v-if="!isMobileOrTablet && whatsappNumber"
              class="z-40"
              :whatsapp-number="whatsappNumber"
            />

            <LayoutBottomNavigation
              v-if="isMobileOrTablet &&!routeIsForMaterialPage()"
              :show-cart="store.cart_visible"
              class="fixed w-full bottom-0 z-40"
            >
              <template #right>
                <SelaLink to="/" class="link">
                  <v-icon icon="mdi mdi-home" :size="24" class="block" />
                </SelaLink>
                <SelaLink to="/categories" class="link">
                  <v-icon
                    icon="mdi mdi-view-grid-plus"
                    :size="24"
                    class="block"
                  />
                </SelaLink>
              </template>

              <template #left>
                <SelaLink to="/offers" class="link">
                  <v-icon :size="24" class="block">
                    <IconsTagPrice />
                  </v-icon>
                </SelaLink>
                <a :href="`https://wa.me/${whatsappNumber}`" class="link" target="_blank">
                  <v-icon icon="mdi mdi-whatsapp" :size="24" class="block" />
                </a>
              </template>
            </LayoutBottomNavigation>

            <div class="bg-nav_color sm:py-5">
              <LayoutFooterActionsDomain :store="store" />

              <LayoutFooterActionsStoreSettings />
              <div class="h-20">
              </div>
            </div>
          </footer>
        </div>
      </v-layout>
    </v-locale-provider>
  </v-app>
</template>

<script setup lang="ts">
const { store } = useDomainState()
const { url } = useDomainHost()
const { routeIsForMaterialPage } = useMenuModel()
const { isMobileOrTablet } = useDevice()
const isOpen = ref(false)
const whatsappNumber = useWhatsappNumer(
  false, store.value || undefined, useCurrentBranch(useBranchId(), store.value?.branches)
)
const { t } = useI18n()

const backgroundStyle = computed(() => {
  return store.value?.bg_image
    ? {
        backgroundImage: `url('/_cache_ipx/fit_cover&f_webp&q_80&loading_lazy&w_1000/${store.value?.bg_image}')`,
        backgroundSize: '100%',
        backgroundRepeat: 'repeat-y'
      }
    : {}
})

useHead({
  script: [useSeoLogo(url, store.value?.icon)]
})

</script>

<style scoped>
.v-navigation-drawer.custom {
  @apply w-full max-w-xs bg-bg_color !important;
}
.custom-bg {
  @apply bg-bg_color text-text_color !important;
}
</style>
